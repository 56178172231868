import { Box, Flex } from "@rebass/grid/emotion";
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import { breadcrumbs } from "../../helpers/schema";

import Card from "../../components/Card";
import ContactCard from "../../components/ContactCard";
import Hero from "../../components/Hero";
import Kranicon from "../../components/Kranicon";
import Page from "../../components/Page";
import PageBlock from "../../components/PageBlock";
import ReferenceImage from "../../components/ReferenceImage";
import Tile from "../../components/Tile";

const Referenzen = ({ data }) => (
  <Page
    header={
      <Hero
        fluid={data.sellingImage.fluid}
        icon={<Kranicon icon="immobilien" />}
        title="Referenzen"
        intro="Ein Ausschnitt der erfolgreichsten Highlights des Jahres in Rudolstadt und Umgebung."
      />
    }
  >
    <Helmet
      title="Referenzen"
      meta={[
        {
          name: "description",
          content:
            "Kranich Immobilien vermittelt über das Jahr hinweg viele Immobilien – hier gibt es eine Übersicht zu den Highlights der letzten Zeit.",
        },
      ]}
      script={[
        breadcrumbs({
          crumbs: [
            {
              name: "Referenzen",
              link: "/immobilie-verkaufen-rudolstadt/referenzen",
              position: 2,
            },
          ],
          siteUrl: data.site.siteMetadata.siteUrl,
          siteTitle: data.site.siteMetadata.title,
        }),
      ]}
    />
    <PageBlock>
      {data.references.edges.map((reference, i) => (
        <Box key={i} m={3}>
          <Card>
            <Flex flexWrap="wrap" alignItems="center">
              <Box width={[1, 1 / 3]} mb={[4, 0]}>
                <ReferenceImage
                  fluid={reference.node.frontmatter.image.childImageSharp.fluid}
                />
              </Box>
              <Box width={[1, 2 / 3]} pl={[0, 4]}>
                <h3>
                  {reference.node.frontmatter.location} ·{" "}
                  {reference.node.frontmatter.type}
                </h3>
                <div
                  dangerouslySetInnerHTML={{ __html: reference.node.html }}
                />
              </Box>
            </Flex>
          </Card>
        </Box>
      ))}

      <Tile>
        <Flex justifyContent="center">
          <Box width={[1, null, 1 / 2]}>
            <ContactCard />
          </Box>
        </Flex>
      </Tile>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    sellingImage: imageSharp(
      fluid: { originalName: { regex: "/home-hero/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
    references: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/src/references/" } }
      sort: { fields: [frontmatter___order] }
    ) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            location
            type
            order
          }
          html
        }
      }
    }
  }
`;

export default Referenzen;
