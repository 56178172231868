import { Box } from "@rebass/grid/emotion";
import React from "react";
import Card from "../Card";
import Contact from "../Contact";
import ProfileImage from "../ProfileImage";

const ContactCard = () => (
  <Card red>
    <ProfileImage />
    <Box mt={3}>
      <h3>Herausforderungen gemeinsam angehen</h3>
      <p>
        Möchten Sie mit mir über Ihre Immobilie sprechen? Rufen Sie mich einfach
        an — schnell und unverbindlich.
      </p>
      <Contact />
    </Box>
  </Card>
);

export default ContactCard;
